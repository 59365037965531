import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from '../../assets/logo-pb.png';
import Fundo from '../../assets/fundo-login.jpg';
import { Link } from "react-router-dom";
import './style.css';
import api from '../../services/api';
import SaltPassword from '../../services/md5';

function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);
    const [recoveryMessage, setRecoveryMessage] = useState('');
    const [recoverMsgsucess, setRecoverMsgsucess] = useState(true);

    // Recupera a rota que o usuário tentou acessar, se houver
    const from = location.state?.from?.pathname || '/';

    function ProcessaLogin(e) {
        e.preventDefault();

        setSucesso('');
        setLoading(true);

        api.post('v1/usuarios/login', {
            email,
            senha: SaltPassword(senha)
        })
        .then(response => {   
                     
            localStorage.setItem('sessionToken', response.data.token);
            localStorage.setItem('sessionId', response.data.id_usuario);            
            localStorage.setItem('sessionNome', response.data.nome);
            localStorage.setItem('sessionEmail', email);
            localStorage.setItem('sessionCodCidade', response.data.cod_cidade);
            localStorage.setItem('sessionCidade', response.data.cidade);
            localStorage.setItem('sessionUF', response.data.uf);
            
            setSucesso('S');
            // Redireciona para a rota que o usuário tentou acessar ou para a página principal
            navigate(from);
        })
        .catch(err => {
            console.log(err);
            
            setSucesso('N');
            setLoading(false);
        });
    }

    function RecuperarSenha() {
        setLoading(true);
        setRecoveryMessage('');

        api.post('v1/usuarios/recuperar_senha', { email })
        .then(response => {
            if (response.status !== 200){          
                setRecoveryMessage(response.data);
                setRecoverMsgsucess(false);
                setLoading(false);
            }else{
                setRecoveryMessage('Um link para redefinir sua senha foi enviado para o seu email.');
                setRecoverMsgsucess(true);
                setLoading(false);
            }
        })
        .catch(err => {
            // Verifica se há uma mensagem de erro vinda do servidor
            const errorMessage = err.response && err.response.data && err.response.data.error
                ? err.response.data.error
                : 'Erro ao enviar email de recuperação. Verifique o email e tente novamente.';
            
            setRecoveryMessage(errorMessage);
            setRecoverMsgsucess(false);
            setLoading(false);
        });
    }

    return (
        <div className="row">
            <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
                <form className="form-login mt-5">
                    <h3 className="mb-4 ">Peça seu delivery agora mesmo.</h3>
                    <h6 className="mb-3">Acesse sua conta</h6>

                    <div className="form-floating">
                        <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatingInput" placeholder="E-mail" />
                        <label htmlFor="floatingInput">E-mail</label>
                    </div>

                    <div className="form-floating">
                        <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatingInput2" placeholder="Senha" />
                        <label htmlFor="floatingInput">Senha</label>
                    </div>

                    <button onClick={ProcessaLogin} className="w-100 btn btn-lg btn-danger" disabled={loading}>
                        {loading ? (
                            <div>
                                <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                <span className="ms-2">Enviando...</span>
                            </div>
                        ) : (
                            <span className="ms-2">Acessar</span>
                        )}
                    </button>

                    {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert">E-mail ou senha inválida</div> : null}
                    {recoverMsgsucess? recoveryMessage && <div className="alert alert-info mt-2" role="alert">{recoveryMessage}</div>:
                    <div className="alert alert-danger mt-2" role="alert">{recoveryMessage}</div>}

                    <div className="mt-0">
                        <button type="button" className="btn btn-link" onClick={RecuperarSenha} disabled={loading}>
                            Esqueci minha senha
                        </button>
                    </div>

                    <div className="mt-2">
                        <Link to="/cadastro" state={{ from: location.state?.from }}>
                            Não tenho uma conta. Criar Agora!
                        </Link>
                    </div>
                    <div className="mt-5">
                    <img src={Logo} alt="Delivery Mais" className="mt-5"/>
                    </div>
                </form>    
            </div>

            <div className="col-sm-6 px-0 d-none d-sm-block">
                <img className="background-login" src={Fundo} alt="Delivery Mais" />
            </div>
        </div>
    );
}

export default Login;
