import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import './style.css';

function ResetarSenha() {
    const { token } = useParams(); // Captura o token da URL
    const navigate = useNavigate();
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [sucesso, setSucesso] = useState('');
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        
        // Validar se as senhas coincidem
        if (novaSenha !== confirmarSenha) {
            setErro("As senhas não coincidem");
            return;
        }

        setLoading(true);
        setErro('');
        
        api.post("/v1/usuarios/resetar_senha", { token, novaSenha })
            . then(response => {
                    if (response.status === 200) {
                setSucesso("Senha redefinida com sucesso!");
                setLoading(false);

                // Redireciona para a página de login após 2 segundos
                setTimeout(() => navigate("/login"), 2000);
            } else {
                setErro(response.data.error || "Erro ao redefinir a senha.");
                setLoading(false);
            }
            })
            .catch(err => {
                setErro('Erro: '+err.error);
                setLoading(false);
            });
}
    return (
        <div className=" d-flex justify-content-center align-items-center text-center mt-5">
            <form onSubmit={handleSubmit} className="form-resetar-senha">
                <h3>Redefinir Senha</h3>

                {sucesso && <div className="alert alert-success">{sucesso}</div>}
                {erro && <div className="alert alert-danger">{erro}</div>}

                <div className="form-group">
                    <label>Nova Senha</label>
                    <input 
                        type="password" 
                        className="form-control" 
                        value={novaSenha} 
                        onChange={(e) => setNovaSenha(e.target.value)} 
                        required 
                    />
                </div>

                <div className="form-group">
                    <label>Confirmar Nova Senha</label>
                    <input 
                        type="password" 
                        className="form-control " 
                        value={confirmarSenha} 
                        onChange={(e) => setConfirmarSenha(e.target.value)} 
                        required 
                    />
                </div>

                <button type="submit" className="btn btn-danger btr" disabled={loading}>
                    {loading ? "Redefinindo..." : "Redefinir Senha"}
                </button>
            </form>
        </div>
    );
}

export default ResetarSenha;
