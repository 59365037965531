import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from '../../assets/logo-pb.png';
import Fundo from '../../assets/fundo-login.jpg';
import './style.css';
import api from '../../services/api';
import SaltPassword from '../../services/md5';
import InputMask from 'react-input-mask';

function Cadastro() {
    const navigate = useNavigate();
    const location = useLocation();  // useLocation deve ser chamado diretamente no componente
    const from = location.state?.from?.pathname || '/'; // Verifica se há uma rota original ou redireciona para a home
       
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senha2, setSenha2] = useState('');
    const [endereco, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');   
    const [codCidade, setCodCidade] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [fone, setFone] = useState('');
    const [loading, setLoading] = useState(false);
    const [cidades, setCidades] = useState([]);

    function SalvarCidade(e) {
        const selectedText = e.target[e.target.selectedIndex]?.text; // Use optional chaining
        if (selectedText && selectedText.includes(" - ")) {
            const [cid, est] = selectedText.split(" - ");
            setCidade(cid);
            setUF(est);
            setCodCidade(e.target.value);
        } else {
            setMensagem("Cidade ou estado inválido selecionado.");
        }
    }
    

    function ProcessaCadastro(e) {
        e.preventDefault();
        setMensagem('');
        
        if (nome ==='') {
                    setMensagem('Insira um nome para sua conta.');
                    return;
        }
        if (email === '') {
            setMensagem('Insira um e-mail para sua conta.');
            return;
        }
        if (senha !== senha2) {
            setMensagem('As senhas não conferem. Digite novamente.');
            return;
        }
        if (endereco ==='') { 
            setMensagem('Insira um endereço.');
            return;
        }       
        if (complemento ==='') {
            setMensagem('Insira um ponto de referencia no Complemento.');
            return;
        }
        if (bairro ==='') {
            setMensagem('Insira um Bairro.');    
            return;
        }
        if (fone === '') {
            setMensagem('Insira um telefone.');
            return;
        }                          

        setLoading(true);

        api.post('v1/usuarios/registro', {
            nome,
            email,
            senha: senha.length > 0 ? SaltPassword(senha) : '',
            endereco,
            complemento,
            bairro,
            cidade,
            uf,
            fone,
            cod_cidade: codCidade
        })
        .then(response => {
            if (response.status === 201) {
                // Armazenar informações do usuário no localStorage
                localStorage.setItem('sessionToken', response.data.token);
                localStorage.setItem('sessionId', response.data.id_usuario);
                localStorage.setItem('sessionEmail', email);
                localStorage.setItem('sessionNome', nome);
                localStorage.setItem('sessionCodCidade', codCidade);
                localStorage.setItem('sessionCidade', cidade);
                localStorage.setItem('sessionUF', uf);
                
                // Redireciona para a URL original (ou página inicial)
                navigate(from);
            } else {
                setLoading(false);
                setMensagem('Ocorreu um erro no cadastro: ' + response.status);
            }
        })
        .catch(err => {
            if (err.response) {
                setMensagem(err.response.data.erro);
            } else {
                setMensagem('Ocorreu um erro na requisição.');
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        api.get('v1/cidades')
            .then(response => {
                setCidades(response.data);
            })
            .catch(err => {
                console.error("Erro ao carregar cidades:", err);
                setMensagem("Erro ao carregar cidades.");
            });
    }, []);

    return <div className="row">
        <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-cadastro mt-5">
                <h3 className="mb-4">Crie sua conta e faça seu pedido.</h3>
                <h6 className="mb-3">Informe os dados abaixo</h6>

                <div className="form-floating">
                    <input type="text" onChange={(e) => setNome(e.target.value)} className="form-control" id="floatingInput" placeholder="Nome completo" />
                    <label htmlFor="floatingInput">Nome completo</label>
                </div>

                <div className="form-floating">
                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatingInput" placeholder="E-mail" />
                    <label htmlFor="floatingInput">E-mail</label>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatingInput" placeholder="Senha" />
                            <label htmlFor="floatingInput">Senha</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="password" onChange={(e) => setSenha2(e.target.value)} className="form-control" id="floatingInput" placeholder="Confirme a senha" />
                            <label htmlFor="floatingInput">Confirme a senha</label>
                        </div>
                    </div>
                </div>                

                <div className="row">
                    <div className="col-lg-8">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setEndereco(e.target.value)} className="form-control" id="floatingInput" placeholder="Endereço" />
                            <label htmlFor="floatingInput">Endereço</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-4">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setComplemento(e.target.value)} className="form-control" id="floatingInput" placeholder="Compl." />
                            <label htmlFor="floatingInput">Compl.</label>
                        </div>
                    </div>
                </div>     

                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setBairro(e.target.value)} className="form-control" id="floatingInput" placeholder="Bairro" />
                            <label htmlFor="floatingInput">Bairro</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="form-control mb-2">
                            <select name="cidades" id="cidades" onChange={SalvarCidade}>
                                <option value="0000000">Cidade</option>

                                {
                                    cidades.map(c => {
                                        return <option key={c.cod_cidade} value={c.cod_cidade}>{c.cidade} - {c.uf}</option>
                                    })
                                }
                                                               
                            </select>
                        </div>
                    </div>
                </div>

                <div className="form-floating">
                        <InputMask mask="(99) 99999-9999" maskChar=" " value={fone} onChange={(e) => setFone(e.target.value)}  >                            
                         {() => (  <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="floatingInput"
                                    placeholder="FONE"
                                   /> )}
                        </InputMask>
                        <label htmlFor="floatingInput">Telefone</label>
                    </div>

                <button onClick={ProcessaCadastro} className="w-100 btn btn-lg btn-danger" disabled={loading}>
                    {loading ? <div>
                                <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                <span className="ms-2">Enviando...</span>
                                </div> : <span className="ms-2">Criar conta</span>
                    }
                </button>

                {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}

                <div className="mt-5">
                    <Link to="/login">Já tenho uma conta. Fazer login!</Link>
                </div>

                <img src={Logo} alt="Delivery Mais" className="mt-5"/>
            </form>    
        </div>

        <div className="col-sm-6 px-0 d-none d-sm-block">
            <img className="background-cadastro" src={Fundo} alt="Delivery Plus" />
        </div>
    </div>
}

export default Cadastro;