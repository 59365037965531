import { useEffect, useState } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import api from "../../services/api";
import { v4 as uuidv4 } from 'uuid';
import './cartao.css';

export function Cartao(props) {
  const [progress, setProgress] = useState(0);

  const externalReference = uuidv4();
  let cardFormInstance = null; // Variável para armazenar a instância do formulário

  useEffect(() => {
    const initMercadoPago = async () => {
      await loadMercadoPago();
      const mp = new window.MercadoPago("APP_USR-407d373b-26ee-4b70-9818-08c0500915b4");

      
      // Obtenha o `device ID` usando getIdentification
      const deviceId =uuidv4();
      console.log("Device ID:", deviceId); // Verifique o ID para debugging


      cardFormInstance = mp.cardForm({
        amount: String(props.amount),
        iframe: true,
        form: {
          id: "form-checkout",
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número do cartão",
          },
          expirationDate: {
            id: "form-checkout__expirationDate",
            placeholder: "MM/YY",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "Código de segurança",
          },
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular do cartão",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emissor",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Parcelas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número do documento",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) console.warn("Form Mounted handling error:", error);
            else console.log("Form mounted");
          },
          onSubmit: async (event) => {
            event.preventDefault();

            startProgress();

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardFormInstance.getCardFormData();

            try {
              const response = await api.post("/v1/process_payment", {
                token,
                issuer_id,
                payment_method_id,
                transaction_amount: Number(amount),
                installments: Number(installments),
                description: props.description,
                payer: {
                  email,
                  identification: {
                    type: identificationType,
                    number: identificationNumber
                  }                
                },
                device_id: deviceId, // Inclua o Device ID aqui
                external_reference: externalReference,
                notification_url: `https://921c-170-150-221-250.ngrok-free.app/v1/webhook`,
                id_pagador: props.id_pagador,
                id_estabelecimento: props.id_estabelecimento
              });

              if (response.data.status === "approved") {
                props.onPaymentStatusChange("approved");
                props.onPaymentIDChange(response.data.id)
              }
            } catch (error) {
              console.error("Erro ao processar o pagamento:", error);
            } finally {
              // Completa o progresso para 100%
              setProgress(100);
            }
          },
        },
      });
    };

    initMercadoPago();

    // Função de limpeza que desmonta o form
    return () => {
      if (cardFormInstance) {
        cardFormInstance.unmount(); // Desmonta o formulário do Mercado Pago
        cardFormInstance = null;
      }
    };
  }, [props.amount]);

  const startProgress = () => {
    let currentProgress = 0;
    const increment = 90 / 10; // 90% em 10 incrementos
    const interval = 100; // 100ms para cada incremento, totalizando 1 segundo

    const intervalId = setInterval(() => {
      currentProgress += increment;
      setProgress(currentProgress);

      if (currentProgress >= 90) {
        clearInterval(intervalId); // Para o progresso em 90%
      }
    }, interval);
  };

  return (
    <>
      <form id="form-checkout">
        <div id="form-checkout__cardNumber" className="container"></div>
        <div id="form-checkout__expirationDate" className="container"></div>
        <div id="form-checkout__securityCode" className="container"></div>
        <input type="text" id="form-checkout__cardholderName" placeholder="Titular do cartão" />
        <select id="form-checkout__issuer"></select>
        <select id="form-checkout__installments" disabled></select>
        <select id="form-checkout__identificationType"></select>
        <input type="text" id="form-checkout__identificationNumber" placeholder="Número do documento" />
        <input type="email" id="form-checkout__cardholderEmail" placeholder="E-mail" />

        <button type="submit" id="form-checkout__submit">Pagar</button>
        <progress className="progress-bar" value={progress} max="100">Carregando...</progress>
      </form>
    </>
  );
}

export default Cartao;
